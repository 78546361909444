<!--
* 创建人：罗兴
* 日 期：
* 描 述：学籍状态异动
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学籍状态异动</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="toadd">添加</el-button>
      <el-button type="primary" size="mini" @click="toedit">修改</el-button>
      <el-button type="primary" size="mini" @click="todelete">删除</el-button>
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 240"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="200"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bmmc" label="学院" width="200">
        </el-table-column>
        <el-table-column prop="zymc" label="专业" width="200">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="yzt" label="原学籍状态" width="200">
        </el-table-column>
        <el-table-column prop="xzt" label="现学籍状态" width="200">
        </el-table-column>
        <el-table-column prop="ydsj" label="异动时间" width="200">
        </el-table-column>
        <el-table-column prop="ydsm" label="异动说明"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="学生学籍状态异动登记"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-dialog
        title="学生信息(双击选择)"
        :visible.sync="dialogStuVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5 ml20 fcolor42">学号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入学号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getStuDataPageList"
            style="margin-left: 10px"
            >搜索</el-button
          >
        </div>
        <el-table
          :data="selectStuData"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selStu"
        >
          <el-table-column
            property="xh"
            label="学号"
            width="150"
          ></el-table-column>
          <el-table-column
            property="xm"
            label="姓名"
            width="150"
          ></el-table-column>
          <el-table-column property="bjmc" label="班级"></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleStuCurrentChange"
            :current-page="stupagination.page"
            :page-size="stupagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="stupagination.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-scrollbar style="height: 350px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学号" label-width="150px" prop="XH">
                <el-input v-model="forminfo.XH" @focus="selectstu"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="150px" prop="XM">
                <el-input v-model="forminfo.XM" readonly> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="现状态" label-width="150px" prop="XZT">
                <el-select
                  v-model="forminfo.XZT"
                  style="display: block; width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in xjztlist"
                    :key="index"
                    :label="item.zdz"
                    :value="item.zddm"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异动时间" label-width="150px" prop="YDSJ">
                <el-date-picker
                  v-model="forminfo.YDSJ"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="异动说明" label-width="150px" prop="YDSM">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入内容"
              v-model="forminfo.YDSM"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getXSIndexData,
  GetZYListByQX,
  GetBJListByQX,
  getXSPageList,
} from '../../api/jcxx'
import {
  getXJYDPageList,
  saveXJYD,
  getXJYDInfo,
  deleteXJInfo,
} from '../../api/xjyd'
import { baseUrl } from '../../utils/http'
import { getXQList } from '../../api/system'
import store from '../../store/index'
export default {
  data() {
    return {
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      dialogFormVisible: false,
      selxh: '',
      keyValue: '',
      xh: '',
      xm: '',
      sfzh: '',
      nj: '',
      njlist: [],
      bm: '',
      bmlist: [],
      zy: '',
      zylist: [],
      bj: '',
      bjlist: [],
      xqlist: [],
      xjztlist: [],
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      headers: '',
      downerrurl: '',
      downbaseurl: baseUrl,
      formdata: {},
      operation: 1,
      forminfo: {
        ID: '',
        XH: '',
        XM: '',
        YZT: '',
        XZT: '',
        YDSJ: null,
        YDSM: '',
      },
      dialogStuVisible: false,
      selkeyword: '',
      stupagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      selectStuData: [],
      // 表单验证规则对象
      forminfoRules: {
        XH: [{ required: true, message: '请选择学生', trigger: 'blur' }],
        XZT: [
          {
            required: true,
            message: '请选择学籍状态',
            trigger: 'blur',
          },
        ],
        YDSJ: [{ required: true, message: '请选择异动时间', trigger: 'blur' }],
        YDSM: [{ required: true, message: '请填写移动说明', trigger: 'blur' }],
      },
      currdata: {},
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    getXSIndexData().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data.njlist
        this.bmlist = res.data.bmlist
        this.xjztlist = res.data.xjztlist
      }
    })
    getXQList().then((res) => {
      if (res.code === 200) {
        this.xqlist = res.data
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bm }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zy }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zy = ''
      this.forminfo.XBJBH = ''
      this.getZY()
    },
    zychange() {
      this.forminfo.XBJBH = ''
      this.getBJ()
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getXJYDPageList({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.selxh = ''
        this.forminfo.XM = ''
      } else {
        this.keyValue = currentRow.id
        this.selxh = currentRow.xh
        this.forminfo.XM = currentRow.xm
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    todelete() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteXJInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
        this.bm = ''
        this.zy = ''
      })
      this.$refs.formRef.resetFields()
    },
    // 获取分页信息
    getStuDataPageList() {
      getXSPageList({
        queryJson: JSON.stringify({
          keyword: this.selkeyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.stupagination.page = res.data.page
            this.stupagination.total = res.data.total
            this.stupagination.records = res.data.records
            this.selectStuData = res.data.rows
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectstu() {
      if (this.operation === 1) {
        this.dialogStuVisible = true
        this.getStuDataPageList()
      }
    },
    selStu(row, column, event) {
      this.forminfo.XH = row.xh
      this.forminfo.XM = row.xm
      this.dialogStuVisible = false
    },
    handleStuCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.stupagination.page = val
      this.getStuDataPageList()
    },
    toadd() {
      this.dialogFormVisible = true
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.ID = this.keyValue
          saveXJYD(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    // 显示编辑弹出框
    toedit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getXJYDInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.YZT = res.data.yzt
            this.forminfo.XZT = res.data.xzt
            this.forminfo.YDSJ = res.data.ydsj
            this.forminfo.YDSM = res.data.ydsm
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
