import {
  get, post
} from '../utils/http';

// 获取学生班级异动
export function getBJYDPageList(data) {
  return get('xjyd/SY_XJYD_BJYD/GetPageList/', data);
}

// 提交班级异动信息
export function saveBJYD(data) {
  return post('xjyd/SY_XJYD_BJYD/Save/', data);
}

// 获取学生班级异动信息
export function getBJYDInfo(data) {
  return get('xjyd/SY_XJYD_BJYD/GetFormData/', data);
}

// 删除学生异动信息
export function deleteInfo(data) {
  return get('xjyd/SY_XJYD_BJYD/Delete/', data);
}

// 获取学生学籍状态异动记录
export function getXJYDPageList(data) {
  return get('xjyd/SY_XJYD_XJZT/GetPageList/', data);
}

// 提交学籍状态异动信息
export function saveXJYD(data) {
  return post('xjyd/SY_XJYD_XJZT/Save/', data);
}

// 获取学生学籍异动信息
export function getXJYDInfo(data) {
  return get('xjyd/SY_XJYD_XJZT/GetFormData/', data);
}

// 删除学籍异动信息
export function deleteXJInfo(data) {
  return get('xjyd/SY_XJYD_XJZT/Delete/', data);
}
